import React from 'react'
import './ListingList.scss'
// import Swal from 'sweetalert2'
import noImg from '../../assets/images/no-image.png'
import { useNavigate } from 'react-router'
import { getFirst100Words } from '../../helper/utils'
// import { deleteListing } from '../../api/services/listing.service'
import { FaEdit } from 'react-icons/fa'
import { IoMdEye } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import { formatPriceWithCommas } from '../../utils/utils'

const ListingList = ({ item, disableListing }: any) => {
    const navigate = useNavigate()
    const cadSign = `${process.env.REACT_APP_CURRENCY_SIGN}`

    return (
        <div className="listing-list">
            <ul>
                <li>
                    <div className="list-img">
                        {item.images.length > 0 ? (
                            <img src={item.images[0]} alt="img" />
                        ) : (
                            <img src={noImg} alt="img" />
                        )}
                    </div>
                    <div className="lisiting-list-desc">
                        <div className="lisiting-content">
                            <div className="listing-heading">
                                <h4>
                                    {item.listingName}{' '}
                                    <small>
                                        (
                                        {`${
                                            process.env.REACT_APP_CURRENCY_SIGN
                                        }$ ${formatPriceWithCommas(
                                            item?.price
                                        )}`}
                                        )
                                    </small>
                                    {item.status === 'inActive' && (
                                        <span className="status">Disabled</span>
                                    )}
                                </h4>
                            </div>
                            <p>{getFirst100Words(item?.description, 10)}</p>
                        </div>

                        <div className="icons">
                            <FaEdit
                                size={30}
                                onClick={() =>
                                    navigate(
                                        `/start-listing/${item?._id}?type=update`
                                    )
                                }
                            />

                            <IoMdEye
                                size={30}
                                onClick={() =>
                                    navigate(`/listing/${item?._id}`)
                                }
                            />
                            {item.status !== 'inActive' && (
                                <MdDelete
                                    size={30}
                                    onClick={() => disableListing(item?._id)}
                                />
                            )}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ListingList
