import React from 'react'
import './LimboList.scss'
import noImg from '../../assets/images/no-image.png'
import { useNavigate } from 'react-router'
import { getFileExtensionRegex, getFirst100Words } from '../../helper/utils'
// import { IoMdEye } from 'react-icons/io'
import LimboTimer from '../LimboTimer/LimboTimer'
import { formatPriceWithCommas } from '../../utils/utils'
const LimboList = ({ item }: any) => {
    const navigate = useNavigate()
    const currentDate = new Date()
    const imgType = getFileExtensionRegex(item.images[0])
    const cadSign = `${process.env.REACT_APP_CURRENCY_SIGN}$`
    return (
        <div className="limbo-list">
            <ul>
                <li>
                    <div
                        className="list-img"
                        onClick={() => navigate(`/limbo-detail/${item?._id}`)}
                    >
                        {item.images &&
                        item.images.length > 0 &&
                        imgType != 'svg' ? (
                            <img src={item.images[0]} alt="img" />
                        ) : (
                            <img src={noImg} alt="img" />
                        )}
                    </div>
                    <div className="limbo-list-desc">
                        <div className="limbo-content">
                            <div className="hdrow">
                                <div className="info">
                                    <h4>
                                        {item.limboName}{' '}
                                        <span className="status">
                                            ({item.limboType})
                                        </span>{' '}
                                        &nbsp;
                                        <small>
                                            (
                                            {`${
                                                process.env
                                                    .REACT_APP_CURRENCY_SIGN
                                            }$ ${formatPriceWithCommas(
                                                item?.price || 0
                                            )}`}
                                            )
                                        </small>
                                    </h4>

                                    <p>
                                        {getFirst100Words(
                                            item?.description,
                                            10
                                        )}
                                    </p>
                                </div>
                                <div className="time-details">
                                    {item?.endAt > currentDate.toISOString() &&
                                        item?.status != 'closed' && (
                                            <LimboTimer endTime={item?.endAt} />
                                        )}
                                </div>
                            </div>
                        </div>

                        {/* <div className="icons">
                            <IoMdEye
                                size={30}
                                onClick={() =>
                                    navigate(`/limbo-detail/${item?._id}`)
                                }
                            />
                        </div> */}
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default LimboList
