import React, { useState } from 'react'
import Moment from 'react-moment'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './CurrentOfferTable.scss'
import handshakeIcon from '../../assets/svg/icon-handshake.png'
import chatIcon from '../../assets/svg/icon-chat.png'
import { handshakeOffer } from '../../api/services/price.service'
import Chat from '../../pages/Chat/Chat'
import { formatPriceWithCommas } from '../../utils/utils'
const CurrentOfferTable = ({
    data,
    curretOffer,
    limboStatus,
    expireStatus,
    limboDetail,
}: any) => {
    const userRole = localStorage.getItem('userRole')
    const [show, setShow] = useState(false)
    const [handshakeId, setHandshakeId] = useState()
    const handleClose = () => setShow(false)
    const limboId = limboDetail?.limboId || ''

    const handshake = (offerId: any) => {
        setShow(true)
        setHandshakeId(offerId)
    }
    const handleHandshake = () => {
        handshakeOffer(handshakeId)
            .then((response) => {
                setShow(false)
                window.location.reload()
            })
            .catch((error) => console.log(error))
    }
    const chat = (vendorId: any) => {
        console.log(vendorId, 'vendorId')
    }

    const [showChat, setShowChat] = useState({
        shopperId: '',
        vendorId: '',
        limboId: '',
        show: false,
    })

    const toggleShowChat = (
        shopperId: string,
        vendorId: string,
        limboId: string
    ) => {
        setShowChat((prev) => ({
            shopperId,
            vendorId,
            limboId,
            show: !prev.show,
        }))
    }

    const shopperEmail = localStorage?.getItem('email')?.split('"')?.[1]

    return (
        <div className="current-offer-table-section">
            <h3>Current Offers</h3>
            {showChat?.show ? (
                <Chat
                    shopperId={showChat?.shopperId}
                    vendorId={showChat?.vendorId}
                    listingId={showChat?.limboId ? showChat.limboId : ''}
                    closeCallback={() => {
                        setShowChat((prev) => ({ ...prev, show: !prev.show }))
                    }}
                />
            ) : (
                ''
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Handshake</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you like the offer if yes then handshake otherwise cancel
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-grey" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-orange"
                        onClick={handleHandshake}
                    >
                        Handshake
                    </button>
                </Modal.Footer>
            </Modal>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Offer</th>
                        <th>vendor</th>
                        <th align="center"></th>
                    </tr>
                </thead>
                <tbody>
                    {curretOffer?.map((item: any) => (
                        <tr key={item._id}>
                            <td>
                                <Moment format="MMMM Do YYYY, h:mm:ss a">
                                    {item.createdAt}
                                </Moment>
                            </td>
                            <td>{`CAD$ ${formatPriceWithCommas(item.offerPrice)}`}</td>
                            <td>{item.vendorName}</td>
                            <td></td>
                        </tr>
                    ))}
                    {data?.map((item: any) => (
                        <tr key={item._id}>
                            <td>
                                <Moment format="MMMM Do YYYY, h:mm:ss a">
                                    {item.createdAt}
                                </Moment>
                            </td>
                            <td>{`${process.env.REACT_APP_CURRENCY_SIGN}$ ${item.offerPrice}`}</td>
                            <td>{item.vendorName}</td>

                            <td>
                                {userRole == `"shopper"` && (
                                    <>
                                        {limboStatus == 'active' &&
                                            !expireStatus && (
                                                <>
                                                    <a
                                                        onClick={() =>
                                                            handshake(item._id)
                                                        }
                                                        className="actionlink"
                                                    >
                                                        <img
                                                            src={handshakeIcon}
                                                            alt="icon"
                                                        />
                                                    </a>
                                                    <a
                                                        onClick={() => {
                                                            toggleShowChat(
                                                                shopperEmail ||
                                                                    '',
                                                                item.vendorId,
                                                                limboDetail?._id ||
                                                                    ''
                                                            )
                                                        }}
                                                        className="actionlink"
                                                    >
                                                        <img
                                                            src={chatIcon}
                                                            alt="icon"
                                                        />
                                                    </a>
                                                </>
                                            )}
                                    </>
                                )}

                                {limboStatus == 'closed' && item.isApproved && (
                                    <img src={handshakeIcon} alt="icon" />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default CurrentOfferTable
