import React, { useEffect, useState } from 'react'
import { buttonGroup, commonFormFields, initialValues } from './constant'
import { useParams } from 'react-router'
import { saveLimbo } from '../../api/services/limbo.service'
import './limboform.scss'
import {
    getCategoryById,
    getFormFieldValues,
    getVendorsWithingArea,
} from '../../api/services/category.service'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CategoryFormFields from '../../helper/CategoryFormFields'
import FormFields from '../../helper/FormFields'
import MapPopup from '../../components/MapWithUserLocation/MapPopup'

const LimboForm = () => {
    const [formData, setFormData] = useState<any>(initialValues)
    const [formErrors, setFormErrors] = useState<any>({})
    const [limboData, setlimboData] = useState<any>([])
    const [images, setImages] = useState<any>([])
    const [createLimboForm, setCreateLimboForm] = useState<any>({})
    const [parsedLimboJson, setParsedLimboJson] = useState<any>([])
    const [parentLimboFieldId, setParentLimboFieldId] = useState<any>(0)
    const [usersWithinTheSelectedArea, setUsersWithinTheSelectedArea] = useState<any>(0)

    const [show, setShow] = useState(false)

    const { id } = useParams()
    const naviagte = useNavigate()

    const [options, setOptions] = useState<any>([])

    const onImageUpload = (file: any) => {
        setImages(file)
    }

    //api call to get form json
    const getFormDetails = () => {
        getCategoryById(id)
            .then((response) => {
                const jsonObject = JSON.parse(response.data[0]?.limboJson)
                setCreateLimboForm(response.data[0])
                setParsedLimboJson(jsonObject)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    useEffect(() => {
        if (formData?.postalCode) {   
            const payload = {
                limboTypeId: id,
                limboName: createLimboForm.limboName,
                limboType: createLimboForm.limboType,
                description: formData.description,
                notifyAllVendors: formData?.notify,
                price: formData.price?.replace(/\D/g,''),
                endAt: formData.endAt,
                images: images,
                location: formData.location,
                address: formData.address,
                city: formData.city,
                postalCode: formData.postalCode,
                limboDetail: limboData,
                image_url: formData?.image_url,
            }

            getVendorsWithingArea(payload)
            .then((response) => {
                 setUsersWithinTheSelectedArea(response?.data?.count ?? 0)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
        }
    }, [formData])

    //api call to get form field values
    const getFieldValues = (fieldId: any, limboId: any, temp: any) => {
        setParentLimboFieldId(fieldId)

        getFormFieldValues(fieldId, limboId)
            .then((response) => {
                // Check if the item with the same id already exists in the options array
                const itemExists = options.some(
                    (item: any) => item.id === limboId
                )

                // If the item with the same id doesn't exist, add it to the options array
                if (!itemExists) {
                    setOptions((prev: any) => [
                        ...prev,
                        {
                            id: limboId,
                            data: response.data,
                            fieldId: fieldId,
                            action: temp.childTriggerAction,
                        },
                    ])
                } else {
                    // If the item with the same id already exists, update its values
                    setOptions((prev: any) =>
                        prev.map((item: any) =>
                            item.id === limboId
                                ? {
                                      ...item,
                                      data: response.data,
                                      fieldId: fieldId,
                                      action: temp.childTriggerAction,
                                  }
                                : item
                        )
                    )
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    //get form ui on component mount
    useEffect(() => {
        getFormDetails()
    }, [])

    //call form field values api for the initial mount
    useEffect(() => {
        if (parsedLimboJson[0]?.limboFieldId) {
            getFieldValues(0, parsedLimboJson[0]?.limboFieldId, {})
        }
    }, [id, parsedLimboJson[0]?.limboFieldId])

    const handleLocationChange = (locationData: any, circleRadius: number) => {
        setFormData({
            ...formData,
            location: circleRadius,
            city:
                locationData?.components?.city ||
                locationData?.components?.state ||
                '',
            postalCode: locationData?.components?.postcode || '',
        })
    }

    //handle input field on change
    const handleOnChange = async (name: any, value: any) => {
        if (name === 'location') {
            await handleLocationChange(value.locationData, value.circleRadius)

            // const { locationData, circleRadius } = value

            // setFormData({
            //     ...formData,
            //     ...{
            //         location: circleRadius,
            //         city:
            //             locationData?.components?.city ||
            //             locationData?.components?.city_district ||
            //             locationData?.components?.state ||
            //             '',
            //         postalCode: locationData?.components?.postcode || '',
            //     },
            // })
        } else if (name === 'price') {
            const numericValue = value.replace(/\D/g, '')
            const formattedPrice = new Intl.NumberFormat('en-CA').format(
                parseFloat(numericValue || 0)
            )

            setFormData((prev: any) => ({
                ...prev,
                [name]: formattedPrice,
            }))
        } else {
            setFormData((prev: any) => ({ ...prev, [name]: value }))
        }
    }

    //handling limbofields
    const handleLimboField = (
        id: any,
        name: any,
        fieldValueId: any,
        fieldValue: any,
        fieldDataType: any,
        displayOrder: any,
        type: any
    ) => {
        //check if item alreday exits
        const itemExists = limboData.some(
            (item: any) => item.limboFieldId === id
        )

        if (!itemExists) {
            setlimboData((prev: any) => [
                ...prev,
                {
                    limboFieldId: id,
                    limboFieldName: name,
                    fieldValueId: parseInt(fieldValueId),
                    fieldValue: fieldValue,
                    fieldDataType: fieldDataType,
                    displayOrder: displayOrder,
                    ...(type === 'Combo' || type === 'Dropdown'
                        ? { parentLimboFieldId: parentLimboFieldId }
                        : {}),
                    ...(typeof fieldValueId === 'string' && type === 'Combo'
                        ? { userDefinedFieldValue: true }
                        : {}),
                },
            ])
        } else {
            // If the item with the same id already exists, update its values
            setlimboData((prev: any) =>
                prev.map((nitem: any) =>
                    nitem.limboFieldId === id
                        ? {
                              ...nitem,
                              fieldValueId: parseInt(fieldValueId),
                              fieldValue: fieldValue,
                              fieldDataType: fieldDataType,
                              ...(type === 'Combo' || type === 'Dropdown'
                                  ? { parentLimboFieldId: parentLimboFieldId }
                                  : {}),
                              ...(typeof fieldValueId === 'string' &&
                              type === 'Combo'
                                  ? { userDefinedFieldValue: true }
                                  : {}),
                          }
                        : nitem
                )
            )
        }
    }

    //on form submission
    const handleSubmit = async (e: any) => {
        e.preventDefault()

        const payload = {
            limboTypeId: id,
            limboName: createLimboForm.limboName,
            limboType: createLimboForm.limboType,
            description: formData.description,
            notifyAllVendors: formData?.notify,
            price: formData.price?.replace(/\D/g, ''),
            endAt: formData.endAt,
            images: images,
            location: formData.location,
            address: formData.address,
            city: formData.city,
            postalCode: formData.postalCode,
            limboDetail: limboData,
            image_url: formData?.image_url || '',
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        await saveLimbo(payload)
            .then((response) => {
                if (response.status === 200) {
                    // API call was successful (status code 200)
                    toast(`${response.data.message}`, {
                        type: 'success',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                    naviagte('/')
                } else {
                    // API call was not successful, handle the error
                    toast(`${response.status}`, {
                        type: 'error',
                        autoClose: 2000,
                        toastId: 'customId',
                    })
                }
            })
            .catch((error) => {
                toast(`Something went wrong`, {
                    type: 'error',
                    autoClose: 2000,
                    toastId: 'customId',
                })
                // Handle errors that occurred during the API call
            })
    }

    //callback is received when dropdwon item is selected
    const onItemSelect = (item: any, id: any) => {
        const selectedItem = parsedLimboJson.filter(
            (item: any) => item?.limboFieldId === id
        )
        getFieldValues(
            item?.fieldValueId,
            selectedItem[0]?.childLimboFieldId[0],
            selectedItem[0]
        )
    }

    //to get placeholder text for text box fields
    const getPlaceholderText = (field: any) => {
        const emptyText = options?.filter(
            (item: any) => item?.data?.length === 0
        )
        const emptyEle = parsedLimboJson.find(
            (elem: any) => elem?.limboFieldId === emptyText[0]?.id
        )
        if (field?.defaultPlaceholder === 'null') {
            const placeholderText = options?.filter((item: any) => {
                return (
                    Array.isArray(item.action) &&
                    item.action.length > 0 &&
                    item.action[0] === 'GetPlaceholderText'
                )
            })

            return placeholderText[0]?.data[0]?.fieldValue || 'Enter details'
        } else if (emptyEle && field?.limboFieldType === 'Combo') {
            return emptyEle?.parentFieldValue
        } else {
            return field?.defaultPlaceholder
        }
    }

    const handleCheckbox = (event: any, item: any) => {
        const { limboFieldId, limboFieldName, fieldDataType, displayOrder } =
            item
        const isChecked = event.target.checked
        // Find if the item is already present in listingData
        const isItemInLimboData = limboData.some(
            (data: any) => data.limboFieldId === limboFieldId
        )

        if (isChecked && !isItemInLimboData) {
            // Add the item to listingData if it's checked and not already present
            setlimboData((prev: any) => [
                ...prev,
                {
                    limboFieldId: limboFieldId,
                    limboFieldName: limboFieldName,
                    fieldValueId: null,
                    fieldValue: 'Yes',
                    fieldDataType: fieldDataType,
                    displayOrder: displayOrder,
                },
            ])
        } else if (!isChecked && isItemInLimboData) {
            // Remove the item from listingData if it's unchecked and already present
            setlimboData((prev: any) =>
                prev.filter((data: any) => data.limboFieldId !== limboFieldId)
            )
        }

        // Toggle visibility based on checkbox state
        const dependentChildField = parsedLimboJson.find(
            (i: any) => i?.limboFieldId === limboFieldId
        )
        const dependentChildLimboFieldId =
            dependentChildField?.childLimboFieldId[0]
        const element = document.getElementById(dependentChildLimboFieldId)

        if (element) {
            element.style.display = isChecked ? 'block' : 'none'
        }
    }

    const getDropdownOptions = (field: any) => {
        const array = field?.parentFieldValue.split('|').map((item: any) => {
            return { fieldValue: item, fieldValueId: item }
        })

        return [
            {
                id: field?.limboFieldId,
                data: array,
                fieldId: field?.parenFieldId,
                action: field.childTriggerAction,
                parentFieldValue: field?.parentFieldValue,
            },
        ]
    }

    const getButton = () => {
        if (
            // !createLimboForm.limboName ||
            // !createLimboForm.limboType ||
            !formData.description ||
            // !formData.location ||
            // !formData.city ||
            // !formData.endAt ||
            // !formData.postalCode ||
            !limboData
        ) {
            return false // Return false if any field is empty
        } else {
            return true // Return true if all fields are filled
        }
    }

    const filteredFields = parsedLimboJson?.filter(
        (field: any) => field?.display === 'Limbo' || field?.display === 'Both'
    )

    return (
        <div className="create-limbo-container">
            <div className="form-container">
                <div className="form-title">
                    <h3>
                        {createLimboForm?.limboType} - &nbsp;
                        {createLimboForm?.limboName}
                    </h3>
                </div>

                <form onSubmit={handleSubmit} className="limbo-form">
                    <div>
                        {filteredFields?.map((field: any) => {
                            return (
                                <CategoryFormFields
                                    formType="limbo"
                                    field={field}
                                    key={field?.limboFieldId}
                                    placeholder={getPlaceholderText(field)}
                                    // value={limboData}

                                    options={
                                        field?.limboFieldType === 'Dropdown' &&
                                        field?.parentLimboFieldId === 'null'
                                            ? getDropdownOptions(field)
                                            : options
                                    }
                                    onItemSelect={onItemSelect}
                                    handleLimboField={handleLimboField}
                                    handleCheckbox={handleCheckbox}
                                />
                            )
                        })}

                        {commonFormFields.map((field, key) => {
                            return (
                                <FormFields
                                    field={field}
                                    key={key}
                                    handleOnChange={handleOnChange}
                                    onImageUpload={onImageUpload}
                                    awsFolderName="limbo"
                                    value={formData[field?.name]}
                                    errors={formErrors}
                                />
                            )
                        })}

                        <div style={{ width: '100%' }}>
                            <button
                                type="button"
                                onClick={() => setShow(!show)}
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    padding: '0.8rem',
                                    margin: '1rem 0rem',
                                }}
                            >
                                Click to select location
                            </button>
                        </div>
                        {<div>Vendors Count: {usersWithinTheSelectedArea}</div>}

                        {show && (
                            <>
                                <MapPopup
                                    text="Search Area Video"
                                    setShow={setShow}
                                    show={show}
                                    placement="form"
                                    handleLocation={handleOnChange}
                                />
                            </>
                        )}
                    </div>
                    <div className="button-group">
                        {buttonGroup.map((item: any, key) => {
                            return (
                                <Button
                                    type={item?.type}
                                    key={key}
                                    id={item?.id}
                                    disabled={!getButton()}
                                >
                                    {item?.label}
                                </Button>
                            )
                        })}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LimboForm
